import React from 'react';
import io from 'socket.io-client';

import Shared from '../shared';

export const SocketProvider = (props) => {
    Shared.socket = io(props.url);
    return <div>{props.children}</div>;
};

export const withSocket = (WrappedComponent) => {
    return class extends React.Component {
        render() {
            return <WrappedComponent {...this.props} socket={Shared.socket} />;
        }
    };
};