import React, { Component } from 'react';
import { withApollo } from 'react-apollo';

import gql from "graphql-tag";

import { withCertificates } from '../../withCertificates';

const EDIT_COMMUNITY = gql`
    mutation editCommunity($communityId: ID!, $updates: JSON!) {
        editCommunity(communityId: $communityId, updates: $updates) {
            success
            message
            communityEdited {
                id
                name
                show_bar
                group
                tools {
                    id
                    url
                    name
                    component
                }
            }
        }
    }
`;

const GET_USER_COMMUNITIES = gql`
    query getUserCommunities {
        userCommunities {
            id
            name
            show_bar
            group
            tools {
                id
                url
                name
                component
            }   
        }
    }
`;

const ADD_COMMUNITY = gql`
    mutation addCommunityForPPEA($name: String!, $group: String!, $tools: [String]!) {
        addCommunity(name: $name, group: $group, tools: $tools) {
            success
            message
            communityAdded {
                id
                name
                show_bar
                group
                tools {
                    id
                    url
                    name
                    component
                }   
            }
        }
    }
`;

export const withCommunity = (WrappedComponent) => {
    return withApollo(withCertificates(class extends Component {

        render() {
            return <WrappedComponent {...this.props} 
                    isAdm={this.isAdm}
                    createCommunity={this.createCommunity} 
                    updateCommunity={this.updateCommunity} 
            />;
        }

        isAdm = (communityId) => {
            const searchedCertificate = `community_${communityId}_adm`;

            return this.props.hasCertificate(searchedCertificate);
        }

        createCommunity = async (name, group, tools) => {
            
            return await new Promise((resolve) => { // TODO: best practices
                this.props.client.mutate({
                    mutation: ADD_COMMUNITY,
                    variables: {
                        name,
                        group,
                        tools,
                    },
                    /* TODO: error handling when mutation returns error! */
                    
                    update: (proxy, { data: { addCommunity, addCommunity: { communityAdded } } }) => { 

                        if(!addCommunity.success) {
                            resolve(null);
                            return;
                        }

                        this.props.addCertificates([
                            `community_${communityAdded.id}_member`,
                            `community_${communityAdded.id}_adm`,
                        ]);

                        try {
                            const data = proxy.readQuery({
                                query: GET_USER_COMMUNITIES
                            });
    
                            data.userCommunities.push(communityAdded);
                            proxy.writeQuery({ query: GET_USER_COMMUNITIES, data });
    
                        } catch(e) {
                            // Do Nothing
                        }

                        resolve(communityAdded);
                    },
                });
            })
        }

        updateCommunity = async (communityId, updates) => {

            await this.props.client.mutate({
                mutation: EDIT_COMMUNITY,
                variables: {
                    communityId,
                    updates,
                },
                /* TODO: error handling when mutation returns error! */
                /*
                update: (proxy, { data: { editCommunity, editCommunity: { communityEdited } } }) => { 

                    if(!editCommunity.success) return;

                    try {
                        const data = proxy.readQuery({
                            query: GET_USER_COMMUNITIES,
                            variables : {
                                communityId
                            }
                        });

                        console.log('updateCommunity', communityEdited, data);

                        // data.messages.messages.push(messageAdded);
                        // proxy.writeQuery({ query: GET_USER_COMMUNITIES, data });

                    } catch(e) {
                        // Do Nothing
                    }
                },
                */
            });
        }

    }));
};

export default withCommunity;