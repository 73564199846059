import React, { Component, Fragment, useState } from 'react';
import { withSocket } from './utils/socketio';
import { graphql, compose } from 'react-apollo';
import gql from "graphql-tag";

import axios from 'axios';

import Header from './components/header';
import CommunityManager from './components/community_manager';
import { Router, navigate } from "@reach/router";
/* import vhCheck from 'vh-check'; */
import { isMobile } from 'react-device-detect';
import { MdReportProblem } from 'react-icons/md';
import { Toaster, Position, Intent, Dialog, Classes, Button, FormGroup, InputGroup, TextArea } from "@blueprintjs/core";

import { withCertificates } from './withCertificates';

import LoginDialog from './components/LoginDialog';
import SignUpDialog from './components/SignUpDialog';

import Recover from './recover';
import Invitation from './invitation';

import './App.css';

import './styles.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLogged: false,
      connected: false,
      connectionFail: false,

      showLogin: false,
      showSignUp: false,
      logging: false,
      showContact: false,
      showRecover: false,
    };

    const { socket } = this.props;

    socket.on('connect', () => this.onConnect());
    socket.on('disconnect', () => this.onDisconnect());
    socket.on('reconnect', () => this.onReconnect());
    socket.on('reconnect_error', () => this.onReconnectError());
  }

  async componentWillMount() {
    if (localStorage.getItem('username')) {
      this.props.socket.emit('add user', localStorage.getItem('username'));
      this.setState({
        isLogged: true,
        userId: localStorage.getItem('userId'),
        name: localStorage.getItem('name'),
        username: localStorage.getItem('username'),
      });

      this.getUserData();
    }
  }

  async getUserData() {
      // REFRESH - update User Data
      const { data } = await axios.get(`${this.props.url}/user`, { headers: { 'x-access-token': localStorage.getItem('token') } });
      
      if(data && data.currentUser) {
        const { name, email, certificates } = data.currentUser;

        localStorage.setItem('name', name);
        localStorage.setItem('username', email);
        if (certificates) this.props.changeCertificates(certificates);
      }
  }

  componentDidMount() {
    // const test = vhCheck();
    this.setState({ style: { minHeight: '50vh' } });
  }

  render() {
    if (isMobile) return <div className="no_mobile">
      <h1><MdReportProblem /></h1>
      Esta fase do projeto n&atilde;o permite o uso em aparelhos m&oacute;veis!
    </div>;

    const { connectionFail, isLogged } = this.state;

    return <div className="wrap" style={this.state.style}>
      <Router>

        <Main default
          tools={this.props.tools}
          connected={this.state.connected}
          connectionFail={connectionFail}
          isLogged={isLogged}
          logging={this.state.logging}
          showLogin={this.handleShowLogin}
          showContact={this.handleShowContact}
          showSignUp={this.handleShowSignUp}
          onLogout={this.handleLogout}
          userName={this.state.name}
          userId={this.state.userId}
          certificates={this.props.certificates}

          onSignUpClose={this.handleCloseSignUp}
          onLoginClose={this.handleCloseLogin}
        />

      </Router>
      <Toaster autoFocus={false} canEscapeKeyClear={true} position={Position.TOP} ref={(ref) => (this.toaster = ref)} />

      <LoginDialog show={this.state.showLogin} onLogin={this.handleLogin} onClose={this.handleCloseLogin} onRecoverPass={this.handleRecoverPass} />
      <RecoverDialog show={this.state.showRecover} onRecover={this.handleRecover} onClose={this.handleCloseRecoverPass} />
      <ContactDialog show={this.state.showContact} userEmail={this.state.username} onSend={this.handleSendContact} onClose={this.handleCloseContact} />
      <SignUpDialog show={this.state.showSignUp} onSignUp={this.handleSignUp} onClose={this.handleCloseSignUp} />
    </div>;
  }

  handleShowLogin = () => {
    this.setState({ showLogin: true });
  }
  handleCloseLogin = () => {
    this.setState({ showLogin: false });
  }
  handleRecoverPass = () => {
    this.setState({ showLogin: false });
    this.setState({ showRecover: true });
  }
  handleCloseRecoverPass = () => {
    this.setState({ showRecover: false });
  }
  handleShowContact = () => {
    this.setState({ showContact: true });
  }
  handleCloseContact = () => {
    this.setState({ showContact: false });
  }
  handleShowSignUp = () => {
    this.setState({ showSignUp: true });
  }
  handleCloseSignUp = () => {
    this.setState({ showSignUp: false });
  }

  handleSignUp = async (name, email, password) => {
    this.setState({
      logging: true
    })

    let error = false;
    let token, user;
    try {
      this.toaster.show({
        message: "Cadastrando...",
        icon: "updated",
        timeout: 0,
      }, 'signingup');

      const { data: { addUser } } = await this.props.addUser({
        variables: { name, email, password },
      })

      this.toaster.dismiss('signingup');

      if (addUser.errorCode === '001') {
        this.toaster.show({
          message: "Já existe um usuário cadastrado com este e-mail!!",
          timeout: 3000,
          icon: "warning-sign",
          intent: Intent.DANGER
        });
        error = true;
      } else {
        user = addUser.user;
        token = addUser.token;
      }
    } catch (err) {
      console.log(err);
      this.toaster.show({
        message: "Problemas para conectar com o servidor!",
        timeout: 3000,
        icon: "warning-sign",
        intent: Intent.DANGER
      });
      error = true;
    }

    if (error) {
      this.setState({ logging: false });

      return;
    }

    localStorage.setItem('userId', user.id);
    localStorage.setItem('name', user.name);
    localStorage.setItem('username', user.email);
    localStorage.setItem('token', token);

    this.props.socket.emit('add user', email);

    this.setState({
      userId: user.id,
      name: user.name,
      username: email,
      isLogged: true,
      logging: false,
      showSignUp: false,
    });
  }

  handleSendContact = async (email, subject, message) => {
    /* let error = false; */

    try {
      this.toaster.show({
        message: "Enviando mensagem...",
        icon: "updated",
        timeout: 0,
      }, 'sending');

      const { data: { sendContact } } = await this.props.sendContact({
        variables: { email, subject, message },
      })

      this.toaster.dismiss('sending');

      if (!sendContact) {
        this.toaster.show({
          message: "Problemas para enviar a mensagem!",
          timeout: 3000,
          icon: "warning-sign",
          intent: Intent.DANGER
        });
        /* error = true; */
      } else {
        this.toaster.show({
          message: "Mensagem enviada!",
          timeout: 3000,
          icon: "tick-circle",
          intent: Intent.SUCCESS,
        });
      }

    } catch (err) {
      console.log(err);
      this.toaster.show({
        message: "Problemas para conectar com o servidor!",
        timeout: 3000,
        icon: "warning-sign",
        intent: Intent.DANGER
      });
      /* error = true; */
    }

    this.setState({
      showContact: false,
    });
  }

  handleLogin = async (email, password) => {
    this.setState({
      logging: true
    })

    let error = false;
    let token, user, certificates;
    try {
      this.toaster.show({
        message: "Logando...",
        icon: "updated",
        timeout: 0,
      }, 'logging');

      const { data: { login } } = await this.props.login({
        variables: { email, password },
      })

      this.toaster.dismiss('logging');

      if (!login) {
        this.toaster.show({
          message: "Login ou Senha incorretos!",
          timeout: 3000,
          icon: "warning-sign",
          intent: Intent.DANGER
        });
        error = true;
      } else {
        user = login.user;
        token = login.token;
        certificates = login.certificates;
      }
    } catch (err) {
      console.log(err);
      this.toaster.show({
        message: "Problemas para conectar com o servidor!",
        timeout: 3000,
        icon: "warning-sign",
        intent: Intent.DANGER
      });
      error = true;
    }

    if (error) {
      this.setState({ logging: false });

      return;
    }

    localStorage.setItem('userId', user.id);
    localStorage.setItem('name', user.name);
    localStorage.setItem('username', user.email);
    localStorage.setItem('token', token);
    if (certificates)
      this.props.changeCertificates(certificates);

    this.props.socket.emit('add user', email);

    this.setState({
      userId: user.id,
      name: user.name,
      username: email,
      isLogged: true,
      logging: false,
      showLogin: false,
    });
  }

  handleRecover = async (email) => {
    console.log('handleRecover', email);

    try {
      this.toaster.show({
        message: "Enviando...",
        icon: "updated",
        timeout: 0,
      }, 'general');

      const { data: { newToken } } = await this.props.recover({
        variables: { email },
      })

      this.toaster.dismiss('general');

      if (!newToken) {
        this.toaster.show({
          message: "Problemas para recuperar sua senha! Este e-mail está cadastrado?",
          timeout: 3000,
          icon: "warning-sign",
          intent: Intent.DANGER
        });
      } else {
        mainToaster.show({
          message: "Uma mensagem de recuperação foi enviada para seu e-mail!",
          timeout: 5000,
          icon: "tick-circle",
          intent: Intent.SUCCESS,
        });
      }
    } catch (err) {
      console.log(err);
      this.toaster.show({
        message: "Problemas para conectar com o servidor!",
        timeout: 3000,
        icon: "warning-sign",
        intent: Intent.DANGER
      });
    }

    this.setState({
      showRecover: false,
    });
  }

  onConnect = () => {
    this.setState({ connected: true });
  }
  onDisconnect() {
    this.setState({ connectionFail: true });
  }
  onReconnect() {
    const { socket } = this.props;

    console.log('onRECONNECT - App')

    socket.emit('add user', this.state.username);
    this.setState({ connectionFail: false });
  }
  onReconnectError() {
    console.log('Reconnection Error...');
  }

  handleLogout = () => {
    this.props.socket.emit('remove user');
    localStorage.removeItem('userId');
    localStorage.removeItem('name');
    localStorage.removeItem('username');
    localStorage.removeItem('token');

    this.props.clearCertificates();

    navigate('/');

    this.setState({
      userId: undefined,
      name: undefined,
      username: undefined,
      token: undefined,
      isLogged: false,
    });
  }
}

let mainToaster;

const Main = props => {
  const handleRecover = () => {
    navigate('/');

    mainToaster.show({
      message: "Senha alterada! Você já pode fazer login!",
      timeout: 5000,
      icon: "tick-circle",
      intent: Intent.SUCCESS,
    });

  }

  return <Fragment>
    <Header {...props} />
    <Router>
      <Recover path="recupera/:token" onRecover={handleRecover} />
      <Invitation path="convite/:token"
        showLogin={props.showLogin}
        showSignUp={props.showSignUp}
        isLogged={props.isLogged}
      />
      <CommunityManager {...props} />
    </Router>

    <Toaster autoFocus={false} canEscapeKeyClear={true} position={Position.TOP} ref={(ref) => (mainToaster = ref)} />
  </Fragment>
};

const ContactDialog = props => {

  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const [errors, setErrors] = useState({
    email: null,
    subject: null,
    message: null,
  });

  const onChangeField = (setter, target) => {
    setter(target.value);
    setErrors({ ...errors, [target.name]: false });
  }

  const send = () => {
    let hasError = false;
    const currentErrors = Object.assign({}, errors);

    if (!props.userEmail && email.length === 0) {
      hasError = true;
      currentErrors.email = 'Este campo não pode ficar em branco!';
    }
    if (subject.length === 0) {
      hasError = true;
      currentErrors.subject = 'Este campo não pode ficar em branco!';
    }
    if (message.length === 0) {
      hasError = true;
      currentErrors.message = 'Este campo não pode ficar em branco!';
    }

    if (hasError) setErrors(currentErrors)
    else {
      props.onSend(props.userEmail ? props.userEmail : email, subject, message);

      setEmail('');
      setSubject('');
      setMessage('');
    }
  }

  return (<Dialog
    autoFocus={true}
    canEscapeKeyClose={true}
    canOutsideClickClose={true}
    enforceFocus={true}
    isOpen={props.show}
    icon="envelope"
    onClose={props.onClose}
    title="Contato"
  >
    <div className={Classes.DIALOG_BODY}>
      <FormGroup
        label="E-Mail"
        labelFor="email-input"
        helperText={errors.email}
        intent={errors.email ? Intent.DANGER : Intent.NONE}
      >
        <InputGroup id="email-input" name="email" placeholder="Digite seu e-mail" disabled={!!props.userEmail} onChange={(e) => onChangeField(setEmail, e.target)} value={props.userEmail ? props.userEmail : email} />
      </FormGroup>

      <FormGroup
        label="Assunto"
        labelFor="subject-input"
        helperText={errors.subject}
        intent={errors.subject ? Intent.DANGER : Intent.NONE}
      >
        <InputGroup id="subject-input" name="subject" placeholder="Digite o assunto" onChange={(e) => onChangeField(setSubject, e.target)} value={subject} />
      </FormGroup>

      <FormGroup
        label="Mensagem"
        labelFor="message-input"
        helperText={errors.message}
        intent={errors.message ? Intent.DANGER : Intent.NONE}
      >
        <TextArea
          id="message-input"
          name="message"
          fill={true}
          growVertically={true}
          large={true}
          intent={Intent.PRIMARY}
          onChange={(e) => onChangeField(setMessage, e.target)}
          value={message}
        />
      </FormGroup>
    </div>

    <div className={Classes.DIALOG_FOOTER}>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button onClick={props.onClose}>Close</Button>
        <Button onClick={send} intent={Intent.PRIMARY}>Enviar</Button>
      </div>
    </div>

  </Dialog>);
};

const RecoverDialog = props => {
  const [email, setEmail] = useState('');

  const [errors, setErrors] = useState({
    email: null,
  });

  const onChangeField = (setter, target) => {
    setter(target.value);
    setErrors({ ...errors, [target.name]: false });
  }

  const recover = () => {
    let hasError = false;
    const currentErrors = Object.assign({}, errors);

    if (email.length === 0) {
      hasError = true;
      currentErrors.email = 'Este campo não pode ficar em branco!';
    }

    if (hasError) setErrors(currentErrors)
    else {
      props.onRecover(email);

      setEmail('');
    }
  }

  return (<Dialog
    autoFocus={true}
    canEscapeKeyClose={true}
    canOutsideClickClose={true}
    enforceFocus={true}
    isOpen={props.show}
    icon="envelope"
    onClose={props.onClose}
    title="Recuperação de senha"
  >
    <div className={Classes.DIALOG_BODY}>
      <FormGroup
        label="E-Mail"
        labelFor="email-input"
        helperText={errors.email}
        intent={errors.email ? Intent.DANGER : Intent.NONE}
      >
        <InputGroup id="email-input" name="email" placeholder="Digite seu e-mail" disabled={!!props.userEmail} onChange={(e) => onChangeField(setEmail, e.target)} value={email} />
      </FormGroup>
    </div>

    <div className={Classes.DIALOG_FOOTER}>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button onClick={props.onClose}>Close</Button>
        <Button onClick={recover} intent={Intent.PRIMARY}>Recuperar</Button>
      </div>
    </div>

  </Dialog>);
}

export default compose(
  graphql(gql`      
    mutation login($email: String!, $password: String!) {
      login(email: $email, password: $password){
        user {
          id
          name
          email          
        }
      token
      certificates
    }
  }`, { name: 'login' }),

  graphql(gql`      
    mutation addUser($name: String, $email: String!, $password: String!) {
      addUser(name: $name, email: $email, password: $password) {
        success
        message
        errorCode
        user {
          id
          name
          email
        }
      token
  }
}
  `, { name: 'addUser' }),

  graphql(gql`      
  mutation sendContact($email: String!, $subject: String!, $message: String!) {
    sendContact(email: $email, subject: $subject, message: $message)
  }
  `, { name: 'sendContact' }),

  graphql(gql`      
  mutation newToken($email: String!) {
    newToken(email: $email)
  }
  `, { name: 'recover' }),

)(withSocket(withCertificates(App)));