import { lazy } from 'react';

export default {
    Profile: lazy(() => import("./ppea/profile")),
    Explore: lazy(() => import("./ppea/explore")),
    ListPPEA: lazy(() => import("./ppea/list")),

    components: {
        "profile.General": lazy(() => import("./ppea/profile/components/General")),
        "profile.GeneralSimple": lazy(() => import("./ppea/profile/components/GeneralSimple")),
        "profile.Candidate": lazy(() => import("./ppea/profile/components/Candidate")),
        "profile.Help": lazy(() => import("./ppea/profile/components/Help")),

        "profile.CandidateAction": lazy(() => import("./ppea/profile/components/CandidateAction")),
        "profile.PromotedAction": lazy(() => import("./ppea/profile/components/PromotedAction")),
        "profile.HelpAction": lazy(() => import("./ppea/profile/components/HelpAction")),

        "content.Context": lazy(() => import("./cms/components/Context")),
    }
}