import React, { Component, Fragment } from 'react';
import { Query } from "react-apollo";
import gql from "graphql-tag";

import classnames from 'classnames';

import {
    Alignment,
    ButtonGroup,
    Button,
    Classes,
    Navbar,
    NavbarGroup,
    NavbarHeading,
    NavbarDivider,
    Menu,
    MenuItem,
    Popover,
    Position,
    Drawer,
    MenuDivider,
} from "@blueprintjs/core";

import ReactHtml from 'raw-html-react';

import './styles.css';

import { FiAlertTriangle } from 'react-icons/fi';

class Header extends Component {

    state = { showContent: null };

    render() {
        const communitiesMenu = (userCommunities) => (
            <Menu>
                {userCommunities.map(community => <MenuItem key={community.id} /*icon="clean"*/ text={community.name} onClick={() => this.handleCommunityChange(community.id)} />)}
            </Menu>
        );

        const userMenuLogged = (
            <Menu>
                {/*<MenuItem icon="person" text="Perfil" />*/}
                <MenuItem icon="envelope" text="Entrar em contato" onClick={this.props.showContact} />
                <MenuItem icon="log-out" text="Sair" onClick={this.props.onLogout} />
            </Menu>
        );

        return <Fragment><Navbar className="main_navbar">
            <NavbarGroup align={Alignment.LEFT}>
                <NavbarHeading className="navbar_h" onClick={this.handleHomeClick} ></NavbarHeading>
            </NavbarGroup>
            <NavbarGroup align={Alignment.RIGHT} style={{maxWidth: '80vw'}}>
                <Button className={classnames(Classes.MINIMAL, 'header_button', 'home_buttom')} icon="home" text="Inicio" onClick={this.handleHomeClick} />
                <NavbarDivider />

                <Query query={GET_CONTENT} fetchPolicy={'cache-and-network'}>
                    {({ loading, error, data }) => {
                        if (loading) return <div className="loading_content">...</div>;
                        if (error) return `Error! ${error.message}`;

                        return <Fragment>
                            {data.content && data.content.content && data.content.content.content && <ContentMenu contents={data.content.content.content} handleShowContent={this.handleShowContent} />}
                        </Fragment>
                    }}
                </Query>

                {!this.props.isLogged &&
                    <Fragment>
                        <NavbarDivider />
                        <Button className={classnames(Classes.MINIMAL, 'header_button', 'espacado')} icon="id-number" text="Cadastro" onClick={this.props.showSignUp} />
                        <NavbarDivider />
                        <Button className={classnames(Classes.MINIMAL, 'header_button', 'espacado')} icon="log-in" text="Acessar" onClick={this.props.showLogin} />
                        <Button className={classnames(Classes.MINIMAL, 'header_button', 'espacado')} icon="envelope" onClick={this.props.showContact} />
                    </Fragment>
                }

                {this.props.isLogged && <ButtonGroup>
                    <Query query={GET_USER_COMMUNITIES} fetchPolicy={'cache-and-network'} variables={{ userId: this.props.userId }}>
                        {({ loading, error, data }) => {
                            if (loading) return "Carregando...";
                            if (error) return `Error! ${error.message}`;

                            return <Fragment>
                                {data.userCommunities.length > 0 && <Popover content={communitiesMenu(data.userCommunities)} position={Position.BOTTOM}>
                                    <Button className={Classes.LARGE} rightIcon="caret-down" icon="graph" text="Comunidades" />
                                </Popover>}
                            </Fragment>;
                        }}
                    </Query>

                    <Popover content={userMenuLogged} position={Position.BOTTOM}>
                        <Button className={Classes.LARGE} rightIcon="caret-down" icon="user" />
                    </Popover>
                </ButtonGroup>}
            </NavbarGroup>
        </Navbar>

            <ShowContent contentId={this.state.showContent} contentTitle={this.state.showContentTitle} handleShowContentClose={this.handleShowContentClose} />

        </Fragment>;
    }

    handleCommunityChange(communityId) {
        this.props.navigate(`/${communityId}`);
    }

    handleHomeClick = () => {
        this.props.navigate("/");
    }

    handleShowContent = (showContent, showContentTitle) => {
        this.setState({ showContent, showContentTitle });
    }
    handleShowContentClose = () => {
        this.setState({ showContent: null, showContentTitle: '' });
    }
}

const ContentMenu = (props) => {
    return <Fragment>
        {props.contents.map((content, i) =>
            <Fragment key={i}>
                {!content.children &&
                    <Button
                        className={classnames(Classes.MINIMAL, 'header_button', 'espacado', { [content.ref]: content.ref })}
                        text={content.title}
                        onClick={() => props.handleShowContent(content.contentId, content.title)}
                    />
                }
                {content.children && <ContentButtonMenu handleShowContent={props.handleShowContent} idx={i} title={content.title} refName={content.ref} children={content.children} />}
            </Fragment>
        )}
    </Fragment>
}

const ContentButtonMenu = (props) => {
    const contentMenu = (
        <Menu>
            {props.children.map((item, i) => {
                if (item.title) return <MenuItem key={i} text={item.title} onClick={() => props.handleShowContent(item.contentId, item.title)} />
                else return <MenuDivider key={i} />
            })}
        </Menu>
    );

    return <Popover content={contentMenu} className={props.refName} position={Position.BOTTOM}>
        <Button className={classnames(Classes.MINIMAL, 'header_button', 'espacado')} text={props.title} />
    </Popover>;
}

/*
const ContentMenuItem = (props) => {
    if(props.children) return props.children.map( (child,i) => <MenuItem key={i} text={props.title}>
        <ContentMenuItem title={child.title} children={child.children} />
    </MenuItem>);

    return <MenuItem text={props.title} />;
}
*/

const ShowContent = (props) => {
    if (!props.contentId) return <div />;
    return <Drawer
        icon="info-sign"
        onClose={props.handleShowContentClose}
        autoFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        enforceFocus={true}
        hasBackdrop={true}
        isOpen={!!props.contentId}
        position={Position.RIGHT}
        size="50%"
        title={props.contentTitle}
        usePortal={true}
        style={{ background: "#ddd" }}
    >
        <Query query={GET_CONTENT} fetchPolicy={'cache-and-network'} variables={{ id: props.contentId }}>
            {({ loading, error, data }) => {
                if (loading) return <div className="loading_content">Carregando conteúdo...</div>;
                if (error) return `Error! ${error.message}`;

                return <div className="content">
                    {data.content && data.content.content && data.content.content && <ReactHtml html={`<div data-react-component="MyComponent">${data.content.content.content}</div>`} componentMap={{ MyComponent }} />}
                </div>
            }}
        </Query>
    </Drawer>;

}

const MyComponent = (props) => {
    return <Fragment></Fragment>;
}

const GET_USER_COMMUNITIES = gql`
    query getUserCommunities {
        userCommunities {
            id
            name
            show_bar
            group
            tools {
                id
                url
                name
                component
            }   
        }
    }
`;

const GET_CONTENT = gql`
    query getContent($id: ID) {
        content(id: $id) {
            id
            content
            alias
            type
            title
        }
    }
`;

export default Header;