import React, { Fragment, Suspense } from 'react';

import tools from '../../tools/';

import { parsePath } from '../../utils';

import {
    Alignment,
    Button,
    Classes,
    Navbar,
    NavbarGroup,
    NavbarHeading,
    Menu,
    MenuItem,
    Popover,
    Position,
} from "@blueprintjs/core";

import './styles.css';

function Community(props) {
    const { connected, location } = props;

    const allTools = {...tools, ...props.tools};

    if (!connected) return <div>Connecting to server...</div>;
    
    const toolsList = props.community.tools.reduce((acc, tool) => {return {...acc, [tool.url]: tool}}, {});
    
    let { tool: currentTool, residual } = parsePath(location.pathname);
    if (!currentTool) currentTool = Object.keys(toolsList)[0];

    const handleToolChange = (tool) => {
        props.navigate(`/${props.community.id}/${tool}`);
    }

    const toolsMenu = (
        <Menu>
            {Object.values(toolsList).map(tool => <MenuItem key={tool.component} text={tool.name} onClick={() => handleToolChange(tool.url)} />)}
        </Menu>
    );

    let communityName = props.community.name;
    if(props.community.group !== null) communityName = `${props.community.group} - ${communityName}`;    

    const Tool = allTools[toolsList[currentTool].component];
    return <Fragment>
        {props.community.show_bar && <Navbar className="community_navbar">
            <NavbarGroup align={Alignment.LEFT}>
                <NavbarHeading><div className="community_title">{communityName}</div></NavbarHeading>
            </NavbarGroup>

            <NavbarGroup align={Alignment.RIGHT}>
                <Popover content={toolsMenu} position={Position.BOTTOM}>
                    <Button className={Classes.MINIMAL} icon="wrench" text="Ferramentas da comunidade" />
                </Popover>
            </NavbarGroup>
        </Navbar>}

        <div className="community_container">
            <Suspense fallback={<div>Carregando Ferramenta...</div>}>
                <Tool 
                    isLogged={props.isLogged} 
                    community={props.community} 
                    communityId={props.community.id} 
                    route={residual} 
                    navigate={props.navigate} 
                    toolPath={currentTool} 
                    certificates={props.certificates}
                />
            </Suspense>
        </div>
    </Fragment>;
}

export default Community;