import { lazy } from 'react';

export default {
    Communication: lazy(() => import("./core/communication")),
    CMS: lazy(() => import("./core/cms")),
    DGraphQL: lazy(() => import("./core/dgraphql")),

    components: {
        "content.Content": lazy(() => import("./core/cms/components/Content")),
        "content.Menu": lazy(() => import("./core/cms/components/Menu")),
        "content.Notification": lazy(() => import("./core/cms/components/Notification")),
        "content.ViewAction": lazy(() => import("./core/cms/components/ViewAction")),
    }
}