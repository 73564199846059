exports.parsePath = (path) => {
    const partes = path.split('/');

    // ["", ""]             -> root
    // ["", "2"]            -> /2
    // ["", "2", "tool"]   -> /2/tool

    let community = null;
    let tool = null;
    let residual = [];

    if(partes.length === 2) {
        if(partes[1] !== '') community = partes[1];
    } else if(partes.length > 2){
        community = partes[1];
        tool = partes[2];
        residual = partes.slice(3);
    }


    return {
        community,
        tool,
        residual,
    }
}