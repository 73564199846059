import React from 'react';

export const withCertificates = (WrappedComponent) => {
    return class extends React.Component {
        render() {
            const certificates = localStorage.getItem('certificates') ? JSON.parse(localStorage.getItem('certificates')) : [];

            return <WrappedComponent {...this.props} 
                certificates={certificates} 
                changeCertificates={this.changeCertificates} 
                clearCertificates={this.clearCertificates}
                addCertificates={this.addCertificates}
                hasCertificate={this.hasCertificate}
            />;
        }

        changeCertificates = (certificates) => {
            localStorage.setItem('certificates', JSON.stringify(certificates));
        }

        clearCertificates = () => {
            localStorage.removeItem('certificates');
        }

        addCertificates = ( newCertificates ) => {
            const currentCertificates = localStorage.getItem('certificates') ? JSON.parse(localStorage.getItem('certificates')) : [];

            const certificates = [...currentCertificates, ...newCertificates];
            localStorage.setItem('certificates', JSON.stringify(certificates));
        }

        hasCertificate = (certificate) => {
            const certificates = localStorage.getItem('certificates') ? JSON.parse(localStorage.getItem('certificates')) : [];
            
            return certificates.includes(certificate);
        }
    };
};

export default withCertificates;