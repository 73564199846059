import React, { useState, useEffect } from 'react';
import { useParams } from "@reach/router";

import { graphql, compose } from 'react-apollo';
import gql from "graphql-tag";

import { Toaster, Button, Card, Elevation, Position, Intent, FormGroup, InputGroup } from "@blueprintjs/core";

import './recover.css';

let toaster = null;

const Recover = props => {
    const params = useParams();

    const [fase, setFase] = useState('none');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');

    const [errors, setErrors] = useState({
        password: null,
        confPassword: null,
    });

    const { token } = params;

    useEffect(() => {
        if (fase === 'none') setFase('verify');
        if (fase === 'verify') {
            setFase('verifying');
            verifyToken();
        }
    },[fase]);

    const verifyToken = async () => {
        console.log('Verificando pedido...', token);

        toaster.show({
            message: "Verificando o seu código...",
            icon: "updated",
            timeout: 0,
        }, 'general');

        const { data: { verifyToken } } = await props.verify({
            variables: { token },
        });

        toaster.dismiss('general');

        if (verifyToken) {
            setFase('verified');
        } else {
            toaster.show({
                message: "Erro! Seu código não pode ser verificado.",
                timeout: 0,
                icon: "warning-sign",
                intent: Intent.DANGER
            });
            setFase('error');
        }
    }

    const onChangeField = (setter, target) => {
        setter(target.value);
        setErrors({ ...errors, [target.name]: false });
    }

    const confirm = () => {
        let hasError = false;
        const currentErrors = Object.assign({}, errors);

        if (password.length === 0) {
            hasError = true;
            currentErrors.password = 'Este campo não pode ficar em branco!';
        }

        if (confPassword.length === 0) {
            hasError = true;
            currentErrors.confPassword = 'Este campo não pode ficar em branco!';
        } else if (confPassword !== password) {
            hasError = true;
            currentErrors.confPassword = 'As senhas devem ser iguais!';
        }

        if (hasError) setErrors(currentErrors)
        else changePassword();
    }

    const changePassword = async () => {
        toaster.show({
            message: "Gravando nova senha...",
            icon: "updated",
            timeout: 0,
        }, 'general');

        /* const { data } =  */await props.change({
            variables: { token, password },
        });

        toaster.dismiss('general');

        if (changePassword) {
            props.onRecover();
        } else {
            toaster.show({
                message: "Problemas para enviar sua nova senha!",
                timeout: 0,
                icon: "warning-sign",
                intent: Intent.DANGER
            });
        }
    }

    return <div className="tela_recover">
        <div className="recover_container">
            <Card interactive={true} elevation={Elevation.TWO}>
                <h3>Recuperação de senha</h3>

                {fase === 'verified' && <div>

                    <FormGroup
                        label="Nova senha"
                        labelFor="password-input"
                        helperText={errors.password}
                        intent={errors.password ? Intent.DANGER : Intent.NONE}
                    >
                        <InputGroup id="password-input" name="password" type="password" placeholder="Digite sua nova senha" value={password} onChange={(e) => onChangeField(setPassword, e.target)} />
                    </FormGroup>

                    <FormGroup
                        label="Confirmação de senha"
                        labelFor="confpassword-input"
                        helperText={errors.confPassword}
                        intent={errors.confPassword ? Intent.DANGER : Intent.NONE}
                    >
                        <InputGroup id="confpassword-input" name="confPassword" type="password" placeholder="Digite a confirmação de senha" value={confPassword} onChange={(e) => onChangeField(setConfPassword, e.target)} />
                    </FormGroup>

                    <Button onClick={confirm} intent={Intent.PRIMARY}>Confirmar</Button>

                </div>}

            </Card>
            <Toaster autoFocus={false} canEscapeKeyClear={true} position={Position.TOP} ref={(ref) => (toaster = ref)} />
        </div>
    </div>
};

export default compose(

    graphql(gql`      
        mutation verifyToken($token: String!) {
            verifyToken(token: $token) {
                id
                name
            }
        }`,
        { name: 'verify' }),

    graphql(gql`      
        mutation changePassword($token: String!, $password: String!) {
            changePassword(token: $token, password: $password)
        }`,
        { name: 'change' }),
)(Recover);