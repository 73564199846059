import React from 'react';
import ReactDOM from 'react-dom';

import Dorothy from './dorothy-cli';

import tools from './tools';

import './custom-styles.css';

const API_URL = process.env.REACT_APP_API_URL;

const dataIdFromObjectMap = {
  'PPEA': (object) => {
    if(object.communityId) return `PPEA:${object.communityId}`;
    
    return null;
  },
  'Suggestion': (object) => {
    if(object.messageId) return `Suggestion:${object.messageId}`;
    
    return null;
  },
};

const contentTypes = {
  'context': {
    name: 'Contexto',
    component: 'Context',
  },
}

ReactDOM.render(
  <Dorothy url={API_URL} tools={tools} dataIdFromObjectMap={dataIdFromObjectMap} contentTypes={contentTypes} />,
  document.getElementById('root'));