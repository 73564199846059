import React, { useState } from 'react';
import { Intent, Dialog, Classes, Button, FormGroup, InputGroup } from "@blueprintjs/core";

const LoginDialog = props => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
  
    const [errors, setErrors] = useState({
      email: null,
      password: null,
    });
  
    const onChangeField = (setter, target) => {
      setter(target.value);
      setErrors({ ...errors, [target.name]: false });
    }
  
    const tryLogin = () => {
      let hasError = false;
      const currentErrors = Object.assign({}, errors);
  
      if (email.length === 0) {
        hasError = true;
        currentErrors.email = 'Este campo não pode ficar em branco!';
      }
      if (password.length === 0) {
        hasError = true;
        currentErrors.password = 'Este campo não pode ficar em branco!';
      }
  
      if (hasError) setErrors(currentErrors)
      else props.onLogin(email, password);
    }
  
    return (<Dialog
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      isOpen={props.show}
      icon="log-in"
      onClose={props.onClose}
      title="Login"
    >
      <div className={Classes.DIALOG_BODY}>
        <FormGroup
          label="E-Mail"
          labelFor="email-input"
          helperText={errors.email}
          intent={errors.email ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup id="email-input" name="email" placeholder="Digite seu e-mail" onChange={(e) => onChangeField(setEmail, e.target)} value={email} />
        </FormGroup>
  
        <FormGroup
          label="Senha"
          labelFor="password-input"
          helperText={errors.password}
          intent={errors.password ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup id="password-input" name="password" type="password" placeholder="Digite sua senha" onChange={(e) => onChangeField(setPassword, e.target)} value={password} />
        </FormGroup>
  
        <Button onClick={props.onRecoverPass}>Esqueci minha senha</Button>
  
      </div>
  
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={props.onClose}>Close</Button>
          <Button onClick={tryLogin} intent={Intent.PRIMARY}>Logar</Button>
        </div>
      </div>
  
    </Dialog>);
  };

  export default LoginDialog;