import React from 'react';


import { ApolloClient } from 'apollo-client';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { ApolloLink } from "apollo-link";
import { createHttpLink } from 'apollo-link-http';
import { ApolloProvider } from 'react-apollo';

import { SocketProvider } from './utils/socketio';
import Shared from './shared';

import App from './App';

function Dorothy(props) {
    Shared.tools = props.tools;

    Shared.contentTypes = {
        'content': {
            name: 'Conteúdo',
            component: 'Content',
        },
        'menu': {
            name: 'Menu',
            component: 'Menu',
        },
        ...props.contentTypes
    };

    const middlewareLink = new ApolloLink((operation, forward) => {
        if (localStorage.getItem("token")) {
            operation.setContext({
                headers: {
                    'x-access-token': localStorage.getItem("token")
                }
            });
        }
        return forward(operation);
    });

    let dataIdFromObjectMap = {
        'MessageResult': (object) => {
            if (object.roomId) return `MessageResult:${object.roomId}`;
            else return `MessageResult:${object.communityId}`;
        },
        'Message': (object) => {
            if (object.id) return `Message:${object.id}`;
            else return null;
        }
    }

    // Mesca com os mapeamentos enviados pela implementação
    if (props.dataIdFromObjectMap) {
        dataIdFromObjectMap = {
            ...props.dataIdFromObjectMap,
            ...dataIdFromObjectMap
        }
    }

    const cache = new InMemoryCache({
        dataIdFromObject: object => {
            let result = null;

            if (dataIdFromObjectMap[object.__typename]) {
                const dataId = dataIdFromObjectMap[object.__typename](object);

                result = dataId ? dataId : defaultDataIdFromObject(object);
            } else {
                result = defaultDataIdFromObject(object);
            }

            if (!result) {
                console.warn(`Warning: Result Type without ID (${object.__typename})`);
            }

            return result;
        }
    });
    const client = new ApolloClient({
        cache,
        link: middlewareLink.concat(
            createHttpLink({
                uri: `${props.url}/graphql`,
            })
        ),
    });

    return <SocketProvider url={props.url}>
        <ApolloProvider client={client}>
            <App tools={props.tools} url={props.url} />
        </ApolloProvider>
    </SocketProvider>;
}

export default Dorothy;
export { default as Communication } from './tools/core/communication';
export { default as withCommunication } from './tools/core/communication/withCommunication';
export { default as withCommunity } from './components/community/withCommunity';