import React, { Component, Suspense } from 'react';

import cx from "classnames";
import './styles.css';

import { TiMessageTyping } from 'react-icons/ti';

import { ButtonGroup } from "@blueprintjs/core";

class Messages extends Component {

    constructor(props) {
        super(props);

        this.state = {
            autoscroll: true,
        }
    }

    render() {
        return <div id="messageScreen">{this.props.messages.map((message, i) => <div key={i}>{message}</div>)}</div>;
    }
    componentDidUpdate() {
        const { autoscroll } = this.state;
        if (autoscroll) {
            this.scrollToBottom();
        }
    }

    componentDidMount() {
        this.$el = document.querySelector('#messageScreen');

        this.$el.addEventListener('scroll', () => {
            if (this.scrollTimeout) clearTimeout(this.scrollTimeout);

            this.scrollTimeout = setTimeout(() => {
                const distanceFromBottom = this.$el.scrollHeight - this.$el.scrollTop - this.$el.clientHeight;
                if (distanceFromBottom <= 100) {
                    if (!this.state.autoscroll) this.setState({ autoscroll: true });
                    this.scrollToBottom();
                } else {
                    if (this.state.autoscroll) this.setState({ autoscroll: false });
                }
            }, 1000);
        });
    }

    scrollToBottom() {
        this.$el && (this.$el.scrollTop = 100000000);
    }
}


function ChatMessage(props) {
    return <div className="chat_message_container">
        <div className={cx("chat_message", { mine: props.mine })}>
            <span className="message_time">{props.time}</span>
            <div className="unsername">{props.username}</div>
            <div className="message" style={{marginTop: '20px'}}>{props.message}</div>            
        </div>
    </div>;
}

function NotificationError(props) {
    return <div className="chat_message_container">
        <div className={cx("chat_message", { mine: props.mine })}>
            <div className="unsername">{props.username}</div>
            <span className="message">Notification Error</span>
        </div>
    </div>;
}

function NotificationMessage(props) {
    // TODO: Este "Carregando Notificação" deveria ser um daqueles objetos visuais de mensagem (fake)    
    return <div className="chat_message_container">
        <div className={cx("notification_message", { mine: props.mine })}>
            <Suspense fallback={<div>Carregando Notificação...</div>}>
                {props.children}
            </Suspense>
            {props.actionComponent && <div className="message_toolbar">
                <ButtonGroup minimal={true}>
                    {props.actionComponent}
                </ButtonGroup>
            </div>}
        </div>
    </div>;
}

function Typing(props) {
    let typing = '';

    if (props.data.length === 1) {
        typing = `${props.data[0]} is typing...`;
    } else if (props.data.length > 1) {
        typing = props.data.join(', ') + ' are typing...';
    }

    return <div>{typing !== '' && <TiMessageTyping />} {typing}</div>;
}

export { Messages, ChatMessage, Typing, NotificationMessage, NotificationError };