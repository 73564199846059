import React, { useState } from 'react';
import { Intent, Dialog, Classes, Button, FormGroup, InputGroup } from "@blueprintjs/core";

const SignUpDialog = props => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
  
    const [errors, setErrors] = useState({
      name: null,
      email: null,
      password: null,
      confPassword: null,
    });
  
    const onChangeField = (setter, target) => {
      setter(target.value);
      setErrors({ ...errors, [target.name]: false });
    }
  
    const trySignUp = () => {
      let hasError = false;
      const currentErrors = Object.assign({}, errors);
  
      if (name.length === 0) {
        hasError = true;
        currentErrors.name = 'Este campo não pode ficar em branco!';
      }
      if (email.length === 0) {
        hasError = true;
        currentErrors.email = 'Este campo não pode ficar em branco!';
      }
      if (password.length === 0) {
        hasError = true;
        currentErrors.password = 'Este campo não pode ficar em branco!';
      }
      if (confPassword !== password) {
        hasError = true;
        currentErrors.confPassword = 'As senhas devem ser iguais!';
      }
  
      if (hasError) setErrors(currentErrors)
      else props.onSignUp(name, email, password);
    }
  
    return (<Dialog
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      isOpen={props.show}
      icon="id-number"
      onClose={props.onClose}
      title="Cadastro"
    >
      <div className={Classes.DIALOG_BODY}>
        <FormGroup
          label="Nome completo"
          labelFor="name-input"
          helperText={errors.name}
          intent={errors.name ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup id="name-input" name="name" placeholder="Digite seu nome" onChange={(e) => onChangeField(setName, e.target)} value={name} />
        </FormGroup>
  
        <FormGroup
          label="E-Mail"
          labelFor="email-input"
          helperText={errors.email}
          intent={errors.email ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup id="email-input" name="email" placeholder="Digite seu e-mail" onChange={(e) => onChangeField(setEmail, e.target)} value={email} />
        </FormGroup>
  
        <FormGroup
          label="Senha"
          labelFor="password-input"
          helperText={errors.password}
          intent={errors.password ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup id="password-input" name="password" type="password" placeholder="Digite sua senha" onChange={(e) => onChangeField(setPassword, e.target)} value={password} />
        </FormGroup>
  
        <FormGroup
          label="Confirmação de senha"
          labelFor="confpassword-input"
          helperText={errors.confPassword}
          intent={errors.confPassword ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup id="confpassword-input" name="confPassword" type="password" placeholder="Digite a confirmação de senha" onChange={(e) => onChangeField(setConfPassword, e.target)} value={confPassword} />
        </FormGroup>
  
  
      </div>
  
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={props.onClose}>Close</Button>
          <Button onClick={trySignUp} intent={Intent.PRIMARY}>Cadastrar</Button>
        </div>
      </div>
  
    </Dialog>);
  };

  export default SignUpDialog;